import React from "react";

const Message = ({ message }) => {
  const renderContent = () => {
    // Regular expression to match Markdown links with optional annotations
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)(?:\{([^\}]+)\})?/g;

    // Split the message content into parts, including text and links
    const parts = [];
    let lastIndex = 0;
    let match;

    // Extract matches and push to parts array
    while ((match = markdownLinkRegex.exec(message.content)) !== null) {
      // Push the text before the link
      if (lastIndex < match.index) {
        parts.push({
          type: "text",
          content: message.content.slice(lastIndex, match.index),
        });
      }

      // Push the link text and URL
      parts.push({ type: "link", text: match[1], url: match[2] });
      lastIndex = markdownLinkRegex.lastIndex;
    }

    // Push any remaining text after the last link
    if (lastIndex < message.content.length) {
      parts.push({ type: "text", content: message.content.slice(lastIndex) });
    }

    // Render the content as JSX
    return parts.map((part, index) => {
      if (part.type === "link") {
        return (
          <a
            href={part.url}
            key={index}
            target="_blank"
            rel="noopener noreferrer"
            style={{ whiteSpace: "pre-line" }}
          >
            {part.text}
          </a>
        );
      } else if (part.type === "text") {
        return (
          <span key={index} style={{ whiteSpace: "pre-line" }}>
            {part.content}
          </span>
        );
      }
      return null;
    });
  };

  return (
    <div
      style={{ textAlign: message.isUser ? "right" : "left", margin: "8px" }}
    >
      <div
        style={{
          color: message.isUser ? "#ffffff" : "#000000",
          backgroundColor: message.isUser ? "#1186fe" : "#eaeaea",
          padding: "15px",
          borderRadius: "8px",
        }}
      >
        {renderContent()}
      </div>
    </div>
  );
};

export default Message;
