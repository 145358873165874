import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  LinearProgress,
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Message from "./Message";
import OpenAI from "openai";
import {
  getProcessAttributesByPartialName,
  getProcessRelationships,
  advanceSearch,
} from "../../actions";

import SendIcon from "@mui/icons-material/Send";
import { useHistory } from "react-router-dom";
const config = require("./openaiConfig");

const Chat = (props) => {
  const [isWaiting, setIsWaiting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [assistant, setAssistant] = useState(null);
  const [thread, setThread] = useState({ messages: [] });
  const [openai, setOpenai] = useState(null);
  const [assistantList, setAssistantList] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState("");
  const messagesEndRef = useRef(null);
  const selectedDatabase = props.user.mainDatabase;
  const isDatabaseValid = config.hasOwnProperty(selectedDatabase);
  const history = useHistory();

  useEffect(() => {
    fetchAssistants();
  }, []);

  useEffect(() => {
    if (!isDatabaseValid) {
      history.push("/home");
    }
  }, [isDatabaseValid, history]);

  useEffect(() => {
    if (assistantList.length > 0) {
      setSelectedAssistant(assistantList[0].id);
    }
  }, [assistantList]);

  useEffect(() => {
    initChatBot();
  }, [selectedAssistant]);

  useEffect(() => {
    if (assistant) {
      const welcomeMessage = `Hi, I'm your personal ${assistant.name}. How can I help you?`;
      setMessages([{ content: welcomeMessage, isUser: false }]);
      setThread({ messages: [{ role: "assistant", content: welcomeMessage }] });
    }
  }, [assistant]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchAssistants = async () => {
    try {
      const openai = new OpenAI({
        apiKey: config[selectedDatabase],
        dangerouslyAllowBrowser: true,
      });

      const assistants = await openai.beta.assistants.list();
      setAssistantList(assistants.data);
      setOpenai(openai);
    } catch (error) {
      console.error("Error fetching assistants:", error.message);
      toast.error("Failed to fetch assistants.");
    }
  };

  const initChatBot = async () => {
    try {
      const selected = assistantList.find(
        (assistant) => assistant.id === selectedAssistant
      );
      if (!selected) return;

      const openai = new OpenAI({
        apiKey: config[selectedDatabase],
        assistantId: selected.id,
        dangerouslyAllowBrowser: true,
      });

      setOpenai(openai);
      setAssistant(selected);
      setThread({
        messages: [{ role: "system", content: "You are a helpful assistant." }],
      });
    } catch (error) {
      console.error("Error initializing assistant:", error.message);
      toast.error("Failed to initialize assistant.");
    }
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return; // Avoid empty messages
    setIsWaiting(true);

    const userMessage = { role: "user", content: input };

    // Add user message to the thread
    setMessages((prev) => [...prev, { content: input, isUser: true }]);
    setThread((prev) => ({
      ...prev,
      messages: [...prev.messages, userMessage],
    }));
    setInput(""); // Clear input field

    try {
      // Step 1: Ask the LLM to determine the user's intent and action dynamically
      const intentResponse = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
          ...thread.messages,
          {
            role: "system",
            content: `
                    You are an intelligent assistant. Analyze the user's input and decide the required action. 
                    Respond strictly in JSON format with the following structure:
                    {
                        "action": "<action_name>",
                        "parameters": {
                            "name": "<optional_parameter>",
                            "target": "<optional_target>"
                        }
                    }

                    Guidelines:
                    - Analyze the user's message and determine the action to take (e.g., "fetch_data", "summarize", "respond").
                    - Always include the "parameters" object, even if it is empty.
                    - For "fetch_data", include "name" in "parameters".
                    - Respond ONLY in JSON format with no additional text.
                    `,
          },
          { role: "user", content: input },
        ],
      });

      const intentAnalysis = JSON.parse(
        intentResponse.choices[0].message.content.trim()
      );

      console.log("Intent Analysis:", intentAnalysis);

      const { action, parameters } = intentAnalysis;

      // Validate the action and parameters
      if (!action) {
        throw new Error("Action is missing in the intent analysis.");
      }

      // Step 2: Handle actions dynamically
      switch (action) {
        case "fetch_data": {
          if (!parameters || !parameters.name) {
            throw new Error(
              "Parameters or 'name' missing for fetch_data action."
            );
          }

          const name = parameters.name.trim();
          const results = await advanceSearch(
            "name",
            name,
            0,
            10,
            "name",
            "ASC",
            ["Business Process"]
          );

          const processes = results.content.map((item) => ({
            name: item.name || "Unknown",
            description:
              item.Description ||
              item.description ||
              "No description available",
          }));

          const responseMessage =
            processes.length > 0
              ? `Here are the processes matching "${name}":\n\n${processes
                  .map(
                    (process, index) =>
                      `${index + 1}. ${process.name}\n   Description: ${
                        process.description
                      }`
                  )
                  .join("\n\n")}`
              : `No processes found matching "${name}".`;

          setMessages((prev) => [
            ...prev,
            { content: responseMessage, isUser: false },
          ]);
          setThread((prev) => ({
            ...prev,
            messages: [
              ...prev.messages,
              { role: "assistant", content: responseMessage },
            ],
          }));
          break;
        }

        case "summarize": {
          if (!parameters || !parameters.name) {
            setMessages((prev) => [
              ...prev,
              {
                content:
                  "The target for summarization was not specified. Please try again.",
                isUser: false,
              },
            ]);
            break;
          }

          // Normalize target name for comparison
          const target = parameters.name.toLowerCase().trim();

          // Split the content into individual processes (assuming they are numbered)
          const processList = messages
            .flatMap((msg) =>
              msg.content
                ? msg.content.split(/\n\d+\.\s+/).map((item) => item.trim()) // Split by numbered list
                : []
            )
            .filter((item) => item); // Remove empty entries

          // Find the process that matches the target
          const matchedProcess = processList.find((process) =>
            process.toLowerCase().includes(target)
          );

          if (!matchedProcess) {
            setMessages((prev) => [
              ...prev,
              {
                content: `No matching process found for "${parameters.name}". Please clarify your request.`,
                isUser: false,
              },
            ]);
            break;
          }

          try {
            // Send the matched process to the LLM for summarization
            const summaryResponse = await openai.chat.completions.create({
              model: "gpt-3.5-turbo",
              messages: [
                ...thread.messages,
                {
                  role: "user",
                  content: `Summarize the following process:\n"${matchedProcess}"`,
                },
              ],
            });

            const assistantSummary =
              summaryResponse.choices[0].message.content.trim();

            setMessages((prev) => [
              ...prev,
              { content: assistantSummary, isUser: false },
            ]);
            setThread((prev) => ({
              ...prev,
              messages: [
                ...prev.messages,
                { role: "assistant", content: assistantSummary },
              ],
            }));
          } catch (error) {
            console.error("Error summarizing process:", error.message);
            toast.error("Failed to summarize the process.");
            setMessages((prev) => [
              ...prev,
              {
                content:
                  "An error occurred during summarization. Please try again.",
                isUser: false,
              },
            ]);
          }
          break;
        }

        case "respond": {
          const response = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: thread.messages.concat(userMessage),
          });

          const assistantMessage = response.choices[0].message.content;
          setMessages((prev) => [
            ...prev,
            { content: assistantMessage.trim(), isUser: false },
          ]);
          setThread((prev) => ({
            ...prev,
            messages: [
              ...prev.messages,
              { role: "assistant", content: assistantMessage.trim() },
            ],
          }));
          break;
        }

        default: {
          setMessages((prev) => [
            ...prev,
            {
              content:
                "I didn't understand your request. Could you rephrase it?",
              isUser: false,
            },
          ]);
          break;
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error(error.message || "Failed to process your request.");
      setMessages((prev) => [
        ...prev,
        { content: "An error occurred. Please try again.", isUser: false },
      ]);
    } finally {
      setIsWaiting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <Grid container direction="column" spacing={2}>
        {messages.map((message, index) => (
          <Grid
            item
            key={index}
            alignSelf={message.isUser ? "flex-end" : "flex-start"}
          >
            <Message message={message} />
          </Grid>
        ))}
        <div ref={messagesEndRef} />
      </Grid>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item xs={9}>
          <TextField
            label="Type your message"
            variant="outlined"
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyPress}
            disabled={isWaiting || !selectedAssistant}
          />
          {isWaiting && <LinearProgress color="inherit" />}
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSendMessage}
            disabled={isWaiting || !selectedAssistant}
          >
            {isWaiting ? <CircularProgress color="inherit" /> : <SendIcon />}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Select
            value={selectedAssistant}
            onChange={(e) => setSelectedAssistant(e.target.value)}
            fullWidth
            disabled={isWaiting}
          >
            {assistantList.map((assistant) => (
              <MenuItem key={assistant.id} value={assistant.id}>
                {assistant.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <ToastContainer />
    </Container>
  );
};

export default Chat;
